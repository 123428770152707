import styled from '@emotion/styled'
import React, { memo } from 'react'

export const Frame = memo(function Frame() {
  return (
    <Aside>
      <Line className="top" />
      <Line className="right" />
      <Line className="bottom" />
      <Line className="left" />
    </Aside>
  )
})

const Aside = styled.aside``

const Line = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  position: fixed;
  z-index: 999999;

  &.top {
    height: 0.375rem;
    top: 0;
    left: 0;
    right: 0;
  }
  &.right {
    width: 0.375rem;
    top: 0;
    right: 0;
    bottom: 0;
  }
  &.bottom {
    height: 0.375rem;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &.left {
    width: 0.375rem;
    top: 0;
    left: 0;
    bottom: 0;
  }
`
